import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styling/component.css";

const ImageSlider = ({ images }) => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    pauseOnHover: false,
    arrows: false,
  };

  return (
    <div className="zoom-image-slider-container">
      <Slider {...settings}>
        {images.map((img, idx) => (
          <div key={idx} className="zoom-slider-image-container">
            <img src={img} alt={`slide ${idx}`} className="zoom-slider-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
