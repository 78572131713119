import React from "react";
import NavBar from "../../components/navigation-header";
import "../../styling/templates.css";
import Slider from "../../components/zoomImageSlider";
import Footer from "../../components/footer";

export default function products() {
  const zoomImages = [
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/products/01.jpg",
    "https://ecospindles-2024.sgp1.cdn.digitaloceanspaces.com/public/react/products/top-view-cleaning-brushes-with-copy-space.jpg",
  ];

  return (
    <div>
      <NavBar />
      <div style={{ position: "relative" }}>
        <Slider images={zoomImages} />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "80vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 0,
          }}
        ></div>
        <div className="video-text-box">
          <div className="video-background-text">
            <h1 className="video-topic">
              CONVERTING WASTE TO
              <br />
              VALUABLE PRODUCTS
            </h1>
          </div>
        </div>
      </div>

      {/* session - 1 */}
      <div style={{ marginTop: "-5px" }}>
        <div className="about-s2-2">
          <center>
            <div className="icon-button-a">
              <div className="icon-button-a-column" id="column1">
                <a href="/products-filament/" aria-label="button">
                  <div className="button-image-1" />
                </a>
                <a
                  href="/products-filament/"
                  aria-label="button"
                  className="link"
                >
                  <h2 className="about-w-button"> MONOFILAMENTS</h2>
                </a>
              </div>
              <div className="icon-button-a-column" id="column2">
                <a href="/product-yarn/" aria-label="button">
                  <div className="button-image-2" />
                </a>
                <a href="/product-yarn/" aria-label="button" className="link">
                  <h2 className="about-w-button">YARN</h2>
                </a>
              </div>
              <div className="icon-button-a-column" id="column2">
                <a href="/pet-recycling/" aria-label="button">
                  <div className="button-image-3" />
                </a>
                <a href="/pet-recycling/" aria-label="button" className="link">
                  <h2 className="about-w-button">PET RECYCLING</h2>
                </a>
              </div>
            </div>
          </center>
        </div>
      </div>

      <Footer />
    </div>
  );
}
